import React, { Component } from 'react';

/* THIS PAGE HAS BEEN MOVED TO NOTIFICATIONS/TOAST */
export default class LookupMultiSelectCode extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.___navigate('/components/forms/select/code');
  }

  render() {
    return <div />;
  }
}
